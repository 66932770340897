// React imports.
import React from "react";

// Asset imports.
import logo from "../assets/logo-2.png"

export const Header = (props) => {    
    const { onButtonClick } = props;
    
    const navigateToIAA = () => {
        window.open("https://iaaonline.net/", "_blank");
    };

    return (
        <header className="sticky top-0 h-20 z-10 header">
            <div className="flex flex-row h-full items-center justify-between">
                {
                    props.displayMenuIcon &&                               
                    <div className="text-center flex-grow-0 flex-shrink-0 w-12">
                        <span 
                            className={"k-icon k-i-menu w-6 text-xl cursor-pointer"} 
                            onClick={onButtonClick}
                        />
                    </div>
                }

                <div className="flex flex-col flex-grow flex-shrink-0 w-auto md:flex-row items-start ml-6">
                    <h1 className="font-extrabold md:text-xl">Members Portal</h1>                  
                </div>
            
                 <div className="hidden md:mr-12 md:flex">
                    <img alt="IAA Logo" src={logo} style={{height:70}} onClick={navigateToIAA}/>                                                                                                               
                </div>                                     
            </div>        
        </header>
    );
};