// React imports.
import React from "react";

// Kendo React imports.
import { TabStrip, TabStripTab, Card, CardBody, CardActions } from "@progress/kendo-react-layout";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

// Custom component imports.
import SectionWindow from "../components/SectionWindow";

// Firebase storage imports.
import storage from "../firebase";
import { ref, getBlob } from "firebase/storage";

const Section = (props) => {
  const handleSelectedTab = (event) => {
    props.handleSelectedTab(event.selected);
  };

  const handleDisplaySectionWindow = (event) => {
    let windowTitle = "";
    let windowBody = "";

    if (props.displaySectionWindow === false) {
      window.scrollTo(0, 0);

      document.body.style.overflow = "hidden";

      windowTitle = event.target.getAttribute("windowtitle");
      windowBody = event.target.getAttribute("windowbody");

      const sectionWindowObject = {
        windowTitle: windowTitle, 
        windowBody: windowBody
      };

      props.handleSectionWindowObject(sectionWindowObject);
    } else {
      document.body.style.overflow = "auto";
    }

    props.handleDisplaySectionWindow(!props.displaySectionWindow);
  };
  
  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");

    a.href = url;
    a.download = filename || "download";

    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };

    a.addEventListener("click", clickHandler, false);
    a.click();

    return a;
  };

  const downloadItemFile = async (event) => {
    const fileName = event.target.getAttribute("filename");

    if (fileName !== "") {   
      const storageRef = ref(storage, fileName);
        
      getBlob(storageRef).then((fileData) => {                      
        downloadBlob(fileData, fileName)
      });     
    } else {
      props.handleDisplayDownloadErrorMessage(true);
    }
  };

  return (
    <div>
      <div className="pb-8 pt-4 rounded text-white mb-8 md:mt-0" style={{backgroundColor: props.sectionData.color}}>       
        <span 
            className="px-4 k-icon k-i-close-circle md:px-0 md:mb-0 md:text-xl mr-4 float-right hover:opacity-75"
            onClick={() => {props.handleDisplaySection(false)}}
        >                           
        </span>
       
        <div className="mx-auto md:w-4/5 text-center float-none pt-4">  
          <h2 className="mb-4 font-light md:text-2xl">     
            {props.sectionData.name}
          </h2>
          <p>
            {props.sectionData.description}
          </p>
        </div>
      </div>

      <TabStrip selected={props.selectedTab} onSelect={handleSelectedTab}>
        <TabStripTab title="Introduction">
          <div>                     
            {props.itemsData.filter(i => i.typeId === 1 && i.format === 1).length > 0 
                ? props.itemsData.filter(i => i.typeId === 1 && i.format === 1).map((item, index) => {                         
                    return(
                        <div                          
                          key={index}
                          dangerouslySetInnerHTML={{ __html: item.overviewText}}
                        >                                             
                        </div>                                                 
                      );
                    }) 
                : <div className="grid grid-cols-5">
                    <div className="text-center col-start-1 md:col-start-2 col-span-3 mx-auto mt-8 mb-8">
                      <p className="md:text-xl">
                          <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
                          <br /><br />
                          Content has not yet been created for this page.
                      </p>
                    </div>   
                </div>                  
            }
          </div>
        </TabStripTab>
        <TabStripTab title="Information">
          <div className="grid gap-8 grid-cols-1 md:grid-cols-5">                     
            {props.itemsData.filter(i => i.typeId === 2).length > 0 
                ? props.itemsData.filter(i => i.typeId === 2).map((item, index) => {                         
                    return(
                      <div 
                          key={index}                                                                                                                                                           
                      >
                      <Card 
                          orientation="vertical"
                          className= "text-white text-center rounded h-48"                    
                          style={{
                            backgroundColor: props.sectionData.color, 
                            borderColor: props.sectionData.color
                        }}                                                                   
                      >
                          <CardBody>                          
                              <p>{item.name}</p>                            
                          </CardBody>
                          <CardActions layout="stretched">
                            {
                              (() => {
                                switch (item.format) {
                                  case 1: return <span 
                                                    className="k-button k-flat k-primary capitalize"                                                      
                                                    windowtitle={item.name}
                                                    windowbody={item.overviewText} 
                                                    onClick={handleDisplaySectionWindow}
                                                >
                                                  View
                                                </span>;                               
                                  default: return <span 
                                                      className="k-button k-flat k-primary capitalize"  
                                                      filename={item.itemFile === null ? "" : item.itemFile.fileName} 
                                                      onClick={downloadItemFile}
                                                  >
                                                    Download
                                                  </span>;  
                                }
                              })()
                            }                                                                 
                        </CardActions>                                          
                      </Card>                                      
                  </div>                                                 
                      );
                    }) 
                : 
                  <div className="text-center col-start-1 md:col-start-2 col-span-3 mt-8 mb-8">
                    <p className="md:text-xl">
                        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
                        <br /><br />
                        Content has not yet been created for this page.
                    </p>
                  </div>                     
            }
          </div>
        </TabStripTab>
        <TabStripTab title="Forms">
          <div className="grid gap-8 grid-cols-1 md:grid-cols-5">                     
            {props.itemsData.filter(i => i.typeId === 3).length > 0 
                ? props.itemsData.filter(i => i.typeId === 3).map((item, index) => {                         
                    return(
                      <div 
                          key={index}                                                                                                                                                           
                      >
                      <Card 
                          orientation="vertical"
                          className= "text-white text-center rounded h-48"                    
                          style={{
                            backgroundColor: props.sectionData.color, 
                            borderColor: props.sectionData.color
                        }}                                                                   
                      >
                          <CardBody>                          
                              <p>{item.name}</p>                              
                          </CardBody>
                          <CardActions layout="stretched">
                            {
                              (() => {
                                switch (item.format) {
                                  case 1: return <span 
                                                    className="k-button k-flat k-primary capitalize"                                                      
                                                    windowtitle={item.name}
                                                    windowbody={item.overviewText} 
                                                    onClick={handleDisplaySectionWindow}
                                                >
                                                  View
                                                </span>;                               
                                  default: return <span 
                                                      className="k-button k-flat k-primary capitalize"  
                                                      filename={item.itemFile === null ? "" : item.itemFile.fileName} 
                                                      onClick={downloadItemFile}
                                                  >
                                                    Download
                                                  </span>;  
                                }
                              })()
                            }                                                                 
                        </CardActions>                                          
                      </Card>                                      
                  </div>                                                 
                      );
                    }) 
                : 
                  <div className="text-center col-start-2 col-span-3 mt-8 mb-8">
                    <p className="md:text-xl">
                        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
                        <br /><br />
                        Content has not yet been created for this page.
                    </p>
                  </div>                     
            }
          </div>
        </TabStripTab>
        <TabStripTab title="Videos">
        <div className="grid gap-8 grid-cols-1 md:grid-cols-5">                     
            {props.itemsData.filter(i => i.typeId === 4).length > 0 
                ? props.itemsData.filter(i => i.typeId === 4).map((item, index) => {                         
                  return(
                    <div 
                        key={index}                                                                                                                                                           
                    >
                    <Card 
                        orientation="vertical"
                        className= "text-white text-center rounded h-48"                    
                        style={{
                          backgroundColor: props.sectionData.color, 
                          borderColor: props.sectionData.color
                      }}                                                                   
                    >
                        <CardBody>                          
                            <p>{item.name}</p>                            
                        </CardBody>
                        <CardActions layout="stretched">
                          {
                            (() => {
                              switch (item.format) {
                                case 1: return <span 
                                                  className="k-button k-flat k-primary capitalize"                                                      
                                                  windowtitle={item.name}
                                                  windowbody={item.overviewText} 
                                                  onClick={handleDisplaySectionWindow}
                                              >
                                                View
                                              </span>;                               
                                default: return <span 
                                                    className="k-button k-flat k-primary capitalize"  
                                                    filename={item.itemFile === null ? "" : item.itemFile.fileName} 
                                                    onClick={downloadItemFile}
                                                >
                                                  Download
                                                </span>;  
                              }
                            })()
                          }                                                                 
                      </CardActions>                                          
                    </Card>                                      
                </div>                                                 
                    );
                  }) 
              : 
                <div className="text-center col-start-1 md:col-start-2 col-span-3 mt-8 mb-8">
                  <p className="md:text-xl">
                      <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
                      <br /><br />
                      Content has not yet been created for this page.
                  </p>
                </div>                     
          }
          </div>
        </TabStripTab>
        <TabStripTab title="Citations">
          <div className="grid gap-8 grid-cols-1 md:grid-cols-5">                     
              {props.itemsData.filter(i => i.typeId === 5).length > 0 
                  ? props.itemsData.filter(i => i.typeId === 5).map((item, index) => {                         
                      return(
                        <div 
                            key={index}                                                                                                                                                           
                        >
                        <Card 
                            orientation="vertical"
                            className= "text-white text-center rounded h-48"
                            style={{
                              backgroundColor: props.sectionData.color, 
                              borderColor: props.sectionData.color
                          }}                                                                   
                        >
                            <CardBody>                          
                              <p>{item.name}</p>                              
                          </CardBody>
                          <CardActions layout="stretched">
                            {
                              (() => {
                                switch (item.format) {
                                  case 1: return <span 
                                                    className="k-button k-flat k-primary capitalize"                                                      
                                                    windowtitle={item.name}
                                                    windowbody={item.overviewText} 
                                                    onClick={handleDisplaySectionWindow}
                                                >
                                                  View
                                                </span>;                               
                                  default: return <span 
                                                      className="k-button k-flat k-primary capitalize"  
                                                      filename={item.itemFile === null ? "" : item.itemFile.fileName} 
                                                      onClick={downloadItemFile}
                                                  >
                                                    Download
                                                  </span>;  
                                  }
                                })()
                              }                                                                 
                          </CardActions>                                                  
                        </Card>                                      
                    </div>                                                 
                        );
                      }) 
                  : <div className="text-center col-start-1 md:col-start-2 col-span-3 mt-8 mb-8">
                      <p className="md:text-xl">
                          <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
                          <br /><br />
                          Content has not yet been created for this page.
                      </p>
                    </div>                     
              }
          </div>
        </TabStripTab>
        <TabStripTab title="Resources">
        <div className="grid gap-8 grid-cols-1 md:grid-cols-5">                     
            {props.itemsData.filter(i => i.typeId === 6).length > 0 
                ? props.itemsData.filter(i => i.typeId === 6).map((item, index) => {                         
                    return(
                      <div 
                          key={index}                                                                                                                                                           
                      >
                      <Card 
                          orientation="vertical"
                          className= "text-white text-center rounded h-48"                    
                          style={{
                            backgroundColor: props.sectionData.color, 
                            borderColor: props.sectionData.color
                        }}                                                                   
                      >
                          <CardBody>                          
                              <p>{item.name}</p>                              
                          </CardBody>
                          <CardActions layout="stretched">
                            {
                              (() => {
                                switch (item.format) {
                                  case 1: return <span 
                                                    className="k-button k-flat k-primary capitalize"                                                      
                                                    windowtitle={item.name}
                                                    windowbody={item.overviewText} 
                                                    onClick={handleDisplaySectionWindow}
                                                >
                                                  View
                                                </span>;                               
                                  default: return <span 
                                                      className="k-button k-flat k-primary capitalize"  
                                                      filename={item.itemFile === null ? "" : item.itemFile.fileName} 
                                                      onClick={downloadItemFile}
                                                  >
                                                    Download
                                                  </span>;  
                                }
                              })()
                            }                                                                 
                        </CardActions>                                          
                      </Card>                                      
                  </div>                                                 
                      );
                    }) 
                : 
                  <div className="text-center col-start-1 md:col-start-2 col-span-3 mt-8 mb-8">
                    <p className="md:text-xl">
                        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
                        <br /><br />
                        Content has not yet been created for this page.
                    </p>
                  </div>                     
            }
          </div>
        </TabStripTab>
        <TabStripTab title="FAQ">
          <div>                     
            {props.itemsData.filter(i => i.typeId === 7 && i.format === 1).length > 0 
                ? props.itemsData.filter(i => i.typeId === 7 && i.format === 1).map((item, index) => {                         
                    return(
                        <div                          
                          key={index}
                          dangerouslySetInnerHTML={{ __html: item.overviewText}}
                        >                                             
                        </div>                                                 
                      );
                    }) 
                : <div className="grid grid-cols-5">
                    <div className="text-center col-start-2 col-span-3 mx-auto mt-8 mb-8">
                      <p className="md:text-xl">
                          <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
                          <br /><br />
                          Content has not yet been created for this page.
                      </p>
                    </div>   
                </div>                  
            }
          </div>
        </TabStripTab>
        <TabStripTab title="Sample Policies">
          <div className="grid gap-8 grid-cols-1 md:grid-cols-5">                     
            {props.itemsData.filter(i => i.typeId === 8).length > 0 
                ? props.itemsData.filter(i => i.typeId === 8).map((item, index) => {                         
                    return(
                      <div 
                          key={index}                                                                                                                                                           
                      >
                      <Card 
                          orientation="vertical"
                          className= "text-white text-center rounded h-48"                    
                          style={{
                            backgroundColor: props.sectionData.color, 
                            borderColor: props.sectionData.color
                        }}                                                                   
                      >
                          <CardBody>                          
                              <p>{item.name}</p>                              
                          </CardBody>
                          <CardActions layout="stretched">
                            {
                              (() => {
                                switch (item.format) {
                                  case 1: return <span 
                                                    className="k-button k-flat k-primary capitalize"                                                      
                                                    windowtitle={item.name}
                                                    windowbody={item.overviewText} 
                                                    onClick={handleDisplaySectionWindow}
                                                >
                                                  View
                                                </span>;                               
                                  default: return <span 
                                                      className="k-button k-flat k-primary capitalize"  
                                                      filename={item.itemFile === null ? "" : item.itemFile.fileName} 
                                                      onClick={downloadItemFile}
                                                  >
                                                    Download
                                                  </span>;  
                                }
                              })()
                            }                                                                 
                        </CardActions>                                          
                      </Card>                                      
                  </div>                                                 
                      );
                    }) 
                : 
                  <div className="text-center col-start-2 col-span-3 mt-8 mb-8">
                    <p className="md:text-xl">
                        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
                        <br /><br />
                        Content has not yet been created for this page.
                    </p>
                  </div>                     
            }
          </div>
        </TabStripTab>
        </TabStrip>

      {props.displaySectionWindow && 
        <SectionWindow 
          handleDisplaySectionWindow={handleDisplaySectionWindow}
          windowBody={props.sectionWindowObject.windowBody}
          windowTitle={props.sectionWindowObject.windowTitle}
          titleBarBackgroundColor={props.sectionData.color}
        >
        </SectionWindow>
      }
      
      <Fade enter={true} exit={true}>
          {props.displayDownloadErrorMessage && 
            <NotificationGroup
              style={{ bottom: 20, right: 20, alignItems: "flex-end" }}
            >
              <Notification
                type={{ style: "error", icon: false }}             
                closable={true}
                onClose={() => props.handleDisplayDownloadErrorMessage(false)}
              >
                <span>Oops! Looks like we couldn't find the specified file.</span>
              </Notification>
            </NotificationGroup>
          }
      </Fade>
    </div>
  );
};

export default Section;