// React imports.
import {React, useState} from "react";

// Kendo React imports.
import { Card, CardTitle, CardBody, CardActions } from "@progress/kendo-react-layout";
import { Toolbar, ToolbarItem } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

// Custom component imports
import Section from "../components/Section";
import SectionWindow from "../components/SectionWindow";

// Axios library imports.
import axios from "axios";

const Dashboard = (props) => {  
    const initialDisplaySectionWindow = false;
    const initialDisplaySectionWindowFromDashboard = false;
    const initialDisplayDownloadErrorMessage = false;
    const initialSelectedTab = 0;
    const initialSectionWindowObject = {};
    const initialSearchValue = "";
    const initialSectionsFilter = {
        logic: "and",
        filters: []
    };  
    const initialItemsFilter = {
        logic: "and",
        filters: []
    };  
       
    const [displayDownloadErrorMessage, setDisplayDownloadErrorMessage] = useState(initialDisplayDownloadErrorMessage);
    const [displaySectionWindow, setDisplaySectionWindow] = useState(initialDisplaySectionWindow);
    const [DisplaySectionWindowFromDashboard, setDisplaySectionWindowFromDashboard] = useState(initialDisplaySectionWindowFromDashboard);
    const [sectionWindowObject, setSectionWindowObject] = useState(initialSectionWindowObject);
    const [sectionsFilter, setSectionsFilter] = useState(initialSectionsFilter);
    const [itemsFilter, setItemsFilter] = useState(initialItemsFilter);
    const [selectedTab, setSelectedTab] = useState(initialSelectedTab);
    const [searchValue, setSearchValue] = useState(initialSearchValue);
    const [titleBarBackgroundColor, setTitleBarBackgroundColor] = useState("#3f51b5");
   
    const handleNotificationClick = () => {
        props.handleDisplayNotification(false);
    };

    const handleSearchInputChange = (event) => {
        setSearchValue(event.value);
    };

    const handleSearchBtnClick = () => {  
        if (searchValue !== "") {
            const headers = {
                headers:{
                  "ngrok-skip-browser-warning": "skip"       
                }
              };

            // Cloud
            const createSearchHistoryRecordUrl = "https://api.compliance.iaaonline.net/Members/CreateSearchHistoryRecord";
            // Localhost
            // const createSearchHistoryRecordUrl = "https://api.compliance.iaaonline.net//Members/CreateSearchHistoryRecord";
            const searchData = {
                SearchString: searchValue
            };

            axios.post(createSearchHistoryRecordUrl, searchData, headers)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {                
                console.log(error);
            });
        }
  
        // const updatedFilters = sectionsFilter;
        // const existingDescriptionFilter = updatedFilters.filters.find(f => f.field === "itemsList");
      
        // if (searchValue !== "" ) {
        //     if (existingDescriptionFilter !== undefined) {                    
        //         existingDescriptionFilter.value = searchValue;
        //     } else {        
        //         const descriptionFilter = { 
        //             field: "itemsList", 
        //             operator:  (itemValue, value) => {
        //                 return itemValue && itemValue.find(item => {
        //                     return item.overviewText.includes(value);
        //                 });
        //             }, 
        //             value: searchValue, ignoreCase: true 
        //         };

        //         updatedFilters.filters.push(descriptionFilter);
        //     }
        // } else {
        //     if (existingDescriptionFilter !== undefined) {
        //         updatedFilters.filters = updatedFilters.filters.filter(f => f.field !== "itemsList");
        //     }
        // }

        // setSectionsFilter(updatedFilters);      

        // props.handleFilteredSectionsData(filterBy(props.sectionsData, sectionsFilter));  
        
        // // Cloud
        // // const getSectionTypeItemsUrl = "https://api.compliance.iaaonline.net/Members/GetSectionTypeItemsBy ";
        // // Localhost
        // const getSectionTypeItemsByKeywordsUrl = "https://api.compliance.iaaonline.net//Members/GetSectionTypeItemsByKeywords";
        
        // axios.get(getSectionTypeItemsByKeywordsUrl, { params: { keywords: searchValue } })
        // .then(response => {
        //     console.log(response.data);                        
        //     // props.handleItemsData(response.data);                  
        // })
        // .catch((error) => {                
        //     console.log(error);
        // });      

        const updatedItemsFilters = itemsFilter;
  
        const existingItemsDescriptionFilter = updatedItemsFilters.filters.find(f => f.field === "overviewText");
        
        if (searchValue !== "") {                      
            if (existingItemsDescriptionFilter !== undefined && existingItemsDescriptionFilter.value !== searchValue) {                     
                existingItemsDescriptionFilter.value = searchValue;         
            } else {
                const searchItemsFilter = 
                { 
                    field: "overviewText", 
                    operator: "contains",
                    value: searchValue     
                };
                
                updatedItemsFilters.filters.push(searchItemsFilter);  
            };         
        } else {
            if (existingItemsDescriptionFilter !== undefined) {
                updatedItemsFilters.filters = updatedItemsFilters.filters.filter(f => f.field !== "overviewText");              
            }
        }

        setItemsFilter(updatedItemsFilters);

        props.handleFilteredDashboardSearchItemsData(filterBy(props.dashboardSearchItemsData, itemsFilter));
        props.handleDisplayDashboardSearchItems(true);
    };

    const handleClearBtnClick = () => {
        setSearchValue(initialSearchValue);                 
        setItemsFilter(initialItemsFilter);

        props.handleFilteredDashboardSearchItemsData(filterBy(props.dashboardSearchItemsData, itemsFilter));
        props.handleDisplayDashboardSearchItems(false);
    };

    // const handleSearchSectionChange = (event) => {
    //     const updatedFilters = sectionsFilter;

    //     const existingSectionFilter = updatedFilters.filters.find(f => f.field === "sectionId");
       
    //     if (event.value.id !== 0 ) {                      
    //         if (existingSectionFilter !== undefined && existingSectionFilter.value !== event.value.id) {                     
    //             existingSectionFilter.value = event.value.id;         
    //         } else {
    //             const sectionFilter = { field: "sectionId", operator: "eq", value: event.value.id };
    //             updatedFilters.filters.push(sectionFilter);  
    //         };         
    //     } else {
    //         if (existingSectionFilter !== undefined) {
    //             updatedFilters.filters = updatedFilters.filters.filter(f => f.field !== "sectionId");
    //         }
    //     }

    //     setSectionsFilter(updatedFilters);
    //     props.handleFilteredSectionsData(filterBy(props.sectionsData, sectionsFilter));
    // };

    // const handleSearchTypeChange = (event) => {
    //     const updatedFilters = sectionsFilter;

    //     const existingTypeFilter = updatedFilters.filters.find(f => f.field === "typesList");

    //     if (event.value.id !== 0) {                    
    //         if (existingTypeFilter !== undefined && existingTypeFilter.value !== event.value.id) {                                  
    //             existingTypeFilter.value = event.value.id;         
    //         } else {
    //             const typeFilter = { field: "typesList", operator: "contains", value: event.value.id };
    //             updatedFilters.filters.push(typeFilter);  
    //         };         
    //     } else {
    //         if (existingTypeFilter !== undefined) {
    //             updatedFilters.filters = updatedFilters.filters.filter(f => f.field !== "typesList");
    //         }
    //     }

    //     setSectionsFilter(updatedFilters);
    //     props.handleFilteredSectionsData(filterBy(props.sectionsData, sectionsFilter));
    // };

    const handleSectionCardClick = (event) => {                
        const cardIndex = event.target.getAttribute("cardindex");

        // Cloud
        const getSectionTypeItemsUrl = "https://api.compliance.iaaonline.net/Members/GetSectionTypeItemsBySectionId";
        // Localhost
        // const getSectionTypeItemsUrl = "https://api.compliance.iaaonline.net//Members/GetSectionTypeItemsBySectionId";
        
        axios.get(getSectionTypeItemsUrl, { headers: {"ngrok-skip-browser-warning": "skip"}, params: { sectionId: cardIndex } })
        .then(response => {               
            props.handleItemsData(response.data);
            props.handleSelectedCardIndex(cardIndex);
            props.handleDisplaySection(true);                         
        })
        .catch((error) => {                
            console.log(error);
        });            
    };
    
    const handleSelectedTab = (selectedTabValue) => {
        setSelectedTab(selectedTabValue);
    };

    const handleDisplaySection = (displaySectionValue) => {
        props.handleDisplaySection(displaySectionValue);
    };

    const handleDisplaySectionWindow = (displaySectionWindowValue) => {
        setDisplaySectionWindow(displaySectionWindowValue);
    };

    const handleSectionWindowObject = (sectionWindowObjectValue) => {
        setSectionWindowObject(sectionWindowObjectValue);
    };

    const handleDisplayDownloadErrorMessage = (displayDownloadErrorMessageValue) => {
        setDisplayDownloadErrorMessage(displayDownloadErrorMessageValue);
    };

    // const handleTitleBarBackgroundColor = (titleBarBackgroundColorValue) => {
    //     setTitleBarBackgroundColor(titleBarBackgroundColorValue);
    // };

    const handleDisplaySectionWindowFromDashboard = (event) => {
        let windowTitle = "";
        let windowBody = "";
    
        if (DisplaySectionWindowFromDashboard === false) {
            const windowCardColor = event.currentTarget.getAttribute("windowCardColor");        
            setTitleBarBackgroundColor(windowCardColor);
         
            window.scrollTo(0, 0);
        
            document.body.style.overflow = "hidden";
        
            windowTitle = event.target.getAttribute("windowtitle");
            windowBody = event.target.getAttribute("windowbody");
                    
            const sectionWindowObject = {
                windowTitle: windowTitle, 
                windowBody: windowBody
            };
        
            handleSectionWindowObject(sectionWindowObject);
        } else {
          document.body.style.overflow = "auto";
        }
    
        setDisplaySectionWindowFromDashboard(!DisplaySectionWindowFromDashboard);
      };

    return (      
        <div>              
            <div className="bg-white md:pt-8 md:mx-16 md:mt-8 md:px-8 md:rounded-t-md">
                <div className={!props.displaySection ? "grid" : "hidden"}>
                    <h2 className="font-light text-center md:mt-0 mt-8 mx-auto mb-8 text-2xl w-4/5">
                        IAA’s IN Compliance Guide is designed to provide relevant and timely information for the apartment industry.
                    </h2>

                    {
                        props.displayNotification && 
                        <Card className="mx-8 mb-8 mt-8 md:mt-0 md:mx-0 md:px-4 md:rounded-md dashboard-notification">                
                            <span 
                                className="px-4 k-icon mt-4 mb-4 k-i-close-circle md:px-0 md:mb-0 md:text-xl ml-auto hover:opacity-75"
                                onClick={handleNotificationClick}
                            >                           
                            </span>
                            <p className="font-light px-6 text-center mx-auto mb-8 md:text-md md:w-11/12 md:px-0">
                                {props.notificationData.text}
                            </p>                   
                        </Card>
                    }

                    <Toolbar className="mx-8 mb-8 mt-8 md:mt-0 md:mx-0 gap-x-4 md:px-4 md:rounded-md">
                        {/* <ToolbarItem>
                            <Label>Search</Label>
                        </ToolbarItem> */}

                        <ToolbarItem>                          
                            <Input 
                                className="bg-white rounded-md w-80"                               
                                onChange={handleSearchInputChange}
                                //style={{width: "300px"}}
                                value={searchValue}
                            /> 
                        </ToolbarItem>
                                             
                        <ToolbarItem>
                            <button
                                type={"submit"}
                                className="k-button normal-case bg-white rounded-md w-80 md:w-40"
                                onClick={handleSearchBtnClick}
                                //style={{width: "150px"}}
                            >
                                Search
                            </button>
                        </ToolbarItem>

                        <ToolbarItem>
                            <button
                                type={"submit"}
                                className="k-button normal-case bg-white rounded-md w-80 md:w-40"
                                onClick={handleClearBtnClick}
                                //style={{width: "150px"}}
                            >
                                Clear
                            </button>
                        </ToolbarItem>

                        {/* <ToolbarItem>
                            <DropDownList
                                className="bg-white md:rounded-md"
                                data={props.sectionsDDLData}
                                defaultItem={{ id: 0, item: "All Sections" }}
                                textField="item"
                                dataItemKey="id"
                                onChange={handleSearchSectionChange}                                                
                            />
                        </ToolbarItem>
                        <ToolbarItem>
                            <DropDownList
                                className="bg-white md:rounded-md"
                                defaultItem={{ id: 0, item: "All Types" }}
                                data={props.typesDDLData}
                                textField="item"
                                dataItemKey="id"
                                onChange={handleSearchTypeChange}                                                  
                            />
                        </ToolbarItem> */}
                    </Toolbar>                                   
                    
                    {!props.displayDashboardSearchItems && 
                        <div className="grid gap-x-10 grid-cols-1 md:grid-cols-3">                     
                            {props.filteredSectionsData.length > 0 
                                ? props.filteredSectionsData.map((card, index) => {                         
                                    return(
                                            <div 
                                                key={index}                                                                                                                                                           
                                            >
                                                <Card 
                                                    orientation="vertical"
                                                    className="h-72 mx-8 mb-8 text-white rounded md:mx-0 md:mb-10 2xl:h-60" 
                                                    style={{
                                                        backgroundColor: card.color, 
                                                        borderColor: card.color
                                                    }}                                              
                                                >
                                                    <CardBody>
                                                        <CardTitle>{card.name}</CardTitle>
                                                        <p>{card.description}</p>                          
                                                    </CardBody>
                                                    <CardActions layout="stretched">
                                                        <span 
                                                            className="k-button k-flat k-primary"  
                                                            cardindex={card.sectionId} 
                                                            onClick={handleSectionCardClick}
                                                        >
                                                            Learn more
                                                        </span>                                          
                                                    </CardActions>                                             
                                                </Card>                                      
                                            </div>
                                        );
                                    }) 
                                : <div className="col-start-2 text-center mx-8 mb-8 md:mx-0 md:mb-10">
                                    <p className="md:text-lg">
                                        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
                                        <br /><br />
                                        Oops! Looks like we couldn't find what you are looking for.
                                    </p>
                                </div>                     
                            }
                        </div>
                    }
                    
                    {props.displayDashboardSearchItems && 
                        <div className="grid gap-8 grid-cols-1 md:grid-cols-5">
                        {props.filteredDashboardSearchItemsData.length > 0 
                                ? props.filteredDashboardSearchItemsData.map((item, index) => {                         
                                    return(
                                        <div 
                                                key={index}                                                                                                                                                           
                                            >
                                            <Card 
                                                orientation="vertical"
                                                className= "text-white text-center rounded mx-8 md:mx-0 md:mb-10 h-48"                    
                                                style={{
                                                    backgroundColor: item.sectionColor, 
                                                    borderColor: item.sectionColor,
                                                }}                                                                   
                                            >
                                                <CardBody>                          
                                                    <p>{item.name}</p>                            
                                                </CardBody>
                                                <CardActions layout="stretched">
                                                    <span 
                                                        className="k-button k-flat k-primary capitalize"                                                      
                                                        windowtitle={item.name}
                                                        windowbody={item.overviewText} 
                                                        onClick={handleDisplaySectionWindowFromDashboard}
                                                        windowCardColor={item.sectionColor}
                                                    >
                                                    View
                                                    </span>                                                                                                                                          
                                                </CardActions>                                          
                                            </Card>                                      
                                        </div>                            
                                        );
                                    }) 
                                : <div className="col-start-3 text-center mx-8 mb-8 md:mx-0 md:mb-10">
                                    <p className="md:text-lg">
                                        <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
                                        <br /><br />
                                        Oops! Looks like we couldn't find what you are looking for.
                                    </p>
                                </div>                      
                            }           
                        </div>
                    }
                </div>

                {props.displaySection &&                
                    <Section                       
                        sectionData={props.sectionsData.find(i => i.sectionId === parseInt(props.selectedCardIndex))}
                        selectedTab={selectedTab}
                        itemsData={props.itemsData}
                        handleSelectedTab={handleSelectedTab}
                        handleDisplaySection={handleDisplaySection}
                        handleDisplaySectionWindow={handleDisplaySectionWindow}
                        displaySectionWindow={displaySectionWindow}
                        handleSectionWindowObject={handleSectionWindowObject}
                        sectionWindowObject={sectionWindowObject}
                        handleDisplayDownloadErrorMessage={handleDisplayDownloadErrorMessage}
                        displayDownloadErrorMessage={displayDownloadErrorMessage}                
                    >
                    </Section>                 
                } 

                {DisplaySectionWindowFromDashboard && 
                    <SectionWindow 
                        handleDisplaySectionWindow={handleDisplaySectionWindowFromDashboard}
                        windowBody={sectionWindowObject.windowBody}
                        windowTitle={sectionWindowObject.windowTitle}
                        titleBarBackgroundColor={titleBarBackgroundColor}
                    >
                    </SectionWindow>
                }  
            </div>           
        </div>
    );
  };
  
export default Dashboard;