// React imports.
import React, { useEffect }  from "react";

// Kendo React imports.
import { Window } from "@progress/kendo-react-dialogs";

// Styling imports.
import { useMediaQuery } from "react-responsive";

const SectionWindow = (props) => {
  const isTabletOrMobile = useMediaQuery(
    { query: "(max-width: 768px)" }
  );

  const handleCopy = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    // Select the element with the .k-window-title-bar class
    const titleBarElement = document.querySelector(".k-window-titlebar");

    // Check if the element exists before applying styles
    if (titleBarElement) {
      titleBarElement.style.backgroundColor = props.titleBarBackgroundColor;
      titleBarElement.style.color = "white";
    }
  }, [props.titleBarBackgroundColor]);

  useEffect(() => {
    const pElements = document.querySelectorAll("div p");

    pElements.forEach((pElement) => {
      pElement.addEventListener("copy", handleCopy);
    });

    // Clean up event listeners when the component unmounts
    return () => {
      pElements.forEach((pElement) => {
        pElement.removeEventListener("copy", handleCopy);
      });
    };
  }, []);

  console.log(isTabletOrMobile);

  const tabletOrPhoneWindowStyle = {
    top: 0,
    left: 0,  
    width: "100%",
    height: "100%"
  };

  return (
    <div> 
       <Window          
            title={props.windowTitle}
            draggable={(isTabletOrMobile ? false : true)}
            resizable={(isTabletOrMobile ? false : true)}
            minimizeButton={false}            
            initialWidth={!isTabletOrMobile && 1000}
            modal={(isTabletOrMobile ? false : true)}
            onClose={props.handleDisplaySectionWindow}
            initialHeight={!isTabletOrMobile && 600}
            style={isTabletOrMobile && tabletOrPhoneWindowStyle}                                    
        > 
            <div onSelect={handleCopy} className="px-8" dangerouslySetInnerHTML={{ __html: props.windowBody}} >
            </div>        
        </Window>                  
    </div>
  );
};

export default SectionWindow;