// React imports.
import {React} from "react";

// Kendo React imports.
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { TextArea, Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const emailValidator = (value) => (emailRegex.test(value) ? "" : "Please enter a valid email.");
const nullValueValidator = value =>!value ? "Please enter a value." : "";

const DescriptionTextArea = (fieldRenderProps) => {
    const { optional, label, ...others } = fieldRenderProps;
    return (
        <div>
            <Label optional={optional}>{label}</Label>                 
            <TextArea                               
                rows={6} 
                {...others}
            />                
        </div>      
    );
};

const LabelInput = (fieldRenderProps) => {
    const { optional, label, ...others } = fieldRenderProps;
    return (
        <div>
            <Label optional={optional}>{label}</Label>                 
            <Input required={!optional} {...others} />                
        </div>      
    );
};

const Contact = (props) => {
    return (      
        <div>              
            <div className="bg-white md:pt-8 md:mx-16 md:mt-8 md:px-8 md:rounded-t-md">
                <div className="grid">
                    <h2 className="font-light text-center md:mt-0 mt-8 mx-auto mb-8 text-2xl w-4/5">
                        Didn’t find what you were looking for?
                    </h2>                          
                </div>

                <Form
                    onSubmit={props.handleSubmitContact}                  
                    render={formRenderProps => (
                        <FormElement className = "md:mx-8 md:my-8 max-w-screen-sm grid">
                            <fieldset className={"k-form-fieldset"}>                     
                                <div className="mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3">
                                    <Field
                                        name={"Name"}
                                        component={LabelInput}
                                        label={"Name"}
                                        validator={nullValueValidator}                            
                                        maxLength={70}
                                        optional={false}                              
                                    />
                                </div>
                                <div className="mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3">
                                    <Field
                                        name={"Email"}
                                        component={LabelInput}
                                        validator={emailValidator}
                                        label={"Email"}                            
                                        maxLength={70}
                                        optional={false}                              
                                    />
                                </div>
                                <div className="mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3">
                                    <Field
                                        name={"Company"}
                                        component={LabelInput}
                                        label={"Company"}                            
                                        maxLength={70}
                                        optional={true}                              
                                    />
                                </div>                                                                                                    
                                <div className="mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3">
                                    <Field
                                        name={"Question"}
                                        component={DescriptionTextArea}
                                        validator={nullValueValidator}
                                        maxLength={800}    
                                        label={"Question"} 
                                        optional={false}                                    
                                    />
                                </div>                                                                                                                                                                 
                            </fieldset>

                            <div className="k-form-buttons mx-6 my-6 md:mx-0 md:mt-0 md:mb-8 max-md:flex max-md:flex-col">
                                <button
                                    type={"submit"}
                                    className="k-button normal-case md:w-1/6 k-primary max-md:mb-4"
                                    disabled={!formRenderProps.allowSubmit}
                                    onClick={formRenderProps.onSubmit}
                                >
                                    Send
                                </button>
                                <button
                                    type={"submit"}
                                    className="k-button normal-case md:w-1/6"                                  
                                    onClick={formRenderProps.onFormReset}
                                >
                                    Clear
                                </button>
                            </div>
                        </FormElement>
                    )}
                />
            </div>

            <Fade enter={true} exit={true}>
                {props.displaySuccessMessage && 
                <NotificationGroup
                    style={{ bottom: 20, right: 20, alignItems: "flex-end" }}
                >
                    <Notification
                        type={{ style: "success", icon: false }}             
                        closable={true}
                        onClose={() => props.handleDisplaySuccessMessage(false)}
                        >
                        <span>Your question has been successfully sent to IAA.</span>
                    </Notification>
                </NotificationGroup>
                }
            </Fade>               
        </div>
    );
  };
  
export default Contact;