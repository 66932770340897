// React imports.
import { React, useState, useEffect } from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import { useCookies } from "react-cookie";

// Routing imports.
import Dashboard from "./pages/Dashboard.js";
import About from "./pages/About.js";
import Contact from "./pages/Contact.js";
import Legal from "./pages/Legal.js";

// Custom component imports.
import LoadingPanel from "./components/LoadingPanel";
import DrawerRouterContainer from "./components/DrawerRouterContainer.js";
import FooterItems from "./components/FooterItems.js";

// Axios library imports.
import axios from "axios";

import { AppBar, AppBarSection } from "@progress/kendo-react-layout";

// Styling imports.
import "@progress/kendo-theme-material/dist/all.css";

import { handleJwtToken } from './novisso/sso';

const App = () => {
  const [cookies, setCookie] = useCookies(["NoviSsoMembersPortalAuth"]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // useEffect(() => {
  //   // Check if the 'jwt' cookie exists and is not expired
  //   const assignJwtCookie = (jwtCookie) => {
  //     setCookie(jwtCookie);
  //   };

  //   const jwtCookie = cookies.NoviSsoMembersPortalAuth;
    
  //   if (jwtCookie) {
  //     // JWT is present and valid, set isLoggedIn to true
  //     setIsLoggedIn(true);

  //     console.log('Cookie exists');
  //   } else {
  //     setIsLoggedIn(false);

  //     handleJwtToken(assignJwtCookie);
  //   }
  // }, [cookies.NoviSsoMembersPortalAuth, setCookie]);

  const initialSectionsDataSettings = [];
  const initialDrawerItemsSettings = [];
  const initialSectionsDDLDataSettings = [];
  const initialTypesDDLDataSettings = [];
  const initialItemsDataSettings = [];
  const initialDashboardSearchItemsSettings = [];
  const initialDisplaySection = false;
  const initialDisplayDashboardSearchItems = false;
  const initialSelectedCardIndex = 0; 
  const initialDataLoadingValue = true;
  const initialDisplayNotification = false;
  const initialNotificationValue = "";
  const initialDisclaimerValue = "";
  const initialOverviewValue = "";
  const initialDisplaySuccessMessage = "";

  const [dataLoading, setDataLoading] = useState(initialDataLoadingValue);
  const [sectionsData, setSectionsData] = useState(initialSectionsDataSettings);
  const [drawerItemsData, setDrawerItemsData] = useState(initialDrawerItemsSettings);
  const [filteredSectionsData, setFilteredSectionsData] = useState(initialSectionsDataSettings);
  const [filteredDashboardSearchItemsData, setFilteredDashboardSearchItemsData] = useState(initialDashboardSearchItemsSettings);
  const [notificationData, setNotificationData] = useState(initialNotificationValue);
  const [disclaimerData, setDisclaimerData] = useState(initialDisclaimerValue);
  const [overviewData, setOverviewData] = useState(initialOverviewValue);
  const [sectionsDDLData, setSectionsDDLData] = useState(initialSectionsDDLDataSettings);
  const [typesDDLData, setTypesDDLData] = useState(initialTypesDDLDataSettings);
  const [dashboardSearchItemsData, setDashboardSearchItemsData] = useState(initialDashboardSearchItemsSettings);
  const [displayNotification, setDisplayNotification] = useState(initialDisplayNotification);
  const [itemsData, setItemsData] = useState(initialItemsDataSettings);   
  const [displaySection, setDisplaySection] = useState(initialDisplaySection);
  const [displayDashboardSearchItems, setDisplayDashboardSearchItems] = useState(initialDisplayDashboardSearchItems);
  const [selectedCardIndex, setSelectedCardIndex] = useState(initialSelectedCardIndex);
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(initialDisplaySuccessMessage);

  useEffect (() => {   
    // Check if the 'jwt' cookie exists and is not expired
    const assignJwtCookie = (jwtCookie) => {
      setCookie(jwtCookie);
    };

    const jwtCookie = cookies.NoviSsoMembersPortalAuth;
    
    if (jwtCookie) {
      // JWT is present and valid, set isLoggedIn to true
      setIsLoggedIn(true);

      console.log('Cookie exists');
    } else {
      setIsLoggedIn(false);

      handleJwtToken(assignJwtCookie);
    }

    let mounted = true;

    // Cloud
    const getSectionsUrl = "https://api.compliance.iaaonline.net/Members/GetSectionsList"; 
    const getSectionsDDLUrl = "https://api.compliance.iaaonline.net/Members/GetSectionsDDL";
    const getTypesDDLUrl = "https://api.compliance.iaaonline.net/Members/GetTypesDDL";
    const getNotificationUrl = "https://api.compliance.iaaonline.net/Members/GetNotification";
    const getDisclaimerUrl = "https://api.compliance.iaaonline.net/Members/GetDisclaimer";
    const getTextItemsListUrl = "https://api.compliance.iaaonline.net/Members/GetTextItemsList";
    const getOverviewUrl = "https://api.compliance.iaaonline.net/Members/GetOverview";

    // Localhost
    // const getSectionsUrl = "https://api.compliance.iaaonline.net//Members/GetSectionsList"; 
    // const getSectionsDDLUrl = "https://api.compliance.iaaonline.net//Members/GetSectionsDDL";
    // const getTypesDDLUrl = "https://api.compliance.iaaonline.net//Members/GetTypesDDL";
    // const getNotificationUrl = "https://api.compliance.iaaonline.net//Members/GetNotification";
    // const getDisclaimerUrl = "https://api.compliance.iaaonline.net//Members/GetDisclaimer";
    // const getTextItemsListUrl = "https://api.compliance.iaaonline.net//Members/GetTextItemsList";
    // const getOverviewUrl = "https://api.compliance.iaaonline.net/Members/GetOverview";

    const headers = {
      headers:{
        "ngrok-skip-browser-warning": "skip"       
      }
    };
   
    axios.all([
        axios.get(getSectionsUrl, headers), 
        axios.get(getNotificationUrl, headers),
        axios.get(getSectionsDDLUrl, headers), 
        axios.get(getTypesDDLUrl, headers),
        axios.get(getDisclaimerUrl, headers),
        axios.get(getTextItemsListUrl, headers),
        axios.get(getOverviewUrl, headers)
    ])
    .then(axios.spread((...responseList) => {        
        if (mounted) {     
          console.log(responseList[0].data);
                                                                           
          setSectionsData(responseList[0].data);
          setFilteredSectionsData(responseList[0].data);

          if (responseList[1].data !== "" && responseList[1].data.text !== "") {               
              setNotificationData(responseList[1].data);
              setDisplayNotification(true);
          }

          if (responseList[4].data !== "" && responseList[4].data.text !== "") {               
            setDisclaimerData(responseList[4].data);
          }

          if (responseList[6].data !== "" && responseList[6].data.text !== "") {               
            setOverviewData(responseList[6].data);
          }

          setSectionsDDLData(responseList[2].data);
          setTypesDDLData(responseList[3].data);
        
          let itemObjectsList = [{ name: "Dashboard", selected: true , route: "/", sectionId: 0 }];

          for (const section of responseList[0].data) {
            let itemObject = {
              name: section.name,
              selected: false,
              route: "/" + section.name,
              sectionId: section.sectionId
            }
                 
            itemObjectsList.push(itemObject);
          }

          console.log(responseList[5].data);
          setDashboardSearchItemsData(responseList[5].data);
                               
          setDrawerItemsData(itemObjectsList);          
          setDataLoading(false);                
        }
    }))
    .catch((error) => {                
        console.log(error);
    });
      
    const cleanup = () => {mounted = false};   
    return cleanup;
  }, [cookies.NoviSsoMembersPortalAuth, setCookie]);

  const setDisplayedSection = (event) => {
    let sectionId;
     
    if (event.itemTarget === undefined) {
      sectionId = event.sectionId;
    } else {
      sectionId = event.itemTarget.props.sectionId;
    }
 
    if (sectionId !== 0) {
      // Cloud
      const getSectionTypeItemsUrl = "https://api.compliance.iaaonline.net/Members/GetSectionTypeItemsBySectionId";
      // Localhost
      // const getSectionTypeItemsUrl = "https://api.compliance.iaaonline.net//Members/GetSectionTypeItemsBySectionId";
     
      axios.get(getSectionTypeItemsUrl, { headers: {"ngrok-skip-browser-warning": "skip"}, params: { sectionId: sectionId } })
      .then(response => {                                                                                              
          setItemsData(response.data);
          setSelectedCardIndex(sectionId);
          setFilteredSectionsData(sectionsData);
          setDisplaySection(true);
          setDisplayDashboardSearchItems(false);                  
      })
      .catch((error) => {                
          console.log(error);
      });
    } else {
      setItemsData([]);
      setSelectedCardIndex(0);
      setDisplaySection(false);
      setDisplayDashboardSearchItems(false);    
    }
  };

  const handleDisplayNotification = (displayNotificationValue) => {
    setDisplayNotification(displayNotificationValue);
  };

  const handleFilteredSectionsData = (filteredSectionsDataValue) => {
    setFilteredSectionsData(filteredSectionsDataValue);
  };

  const handleFilteredDashboardSearchItemsData = (filteredSearchItemsDataValue) => {
    setFilteredDashboardSearchItemsData(filteredSearchItemsDataValue);
  };

  const handleItemsData = (itemsDataValue) => {
    setItemsData(itemsDataValue);
  };

  const handleDisplaySection = (displaySectionValue) => {
    setDisplaySection(displaySectionValue);
  };

  const handleDisplayDashboardSearchItems = (displayDashboardSearchItemsValue) => {
    setDisplayDashboardSearchItems(displayDashboardSearchItemsValue);
  };

  const handleSelectedCardIndex = (selectedCardIndexValue) => {
    setSelectedCardIndex(selectedCardIndexValue);
  };

  const handleDisplaySuccessMessage = (displaySuccessMessageValue) => {
    setDisplaySuccessMessage(displaySuccessMessageValue);
  };

  const handleSubmitContact = (event) => {
    // Cloud
    const sendContactInfoUrl = "https://api.compliance.iaaonline.net/Members/SendContactInfo";
    // Localhost
    //const sendContactInfoUrl = "https://localhost:44380/Members/SendContactInfo";
  
    axios.post(sendContactInfoUrl, event)
        .then((response) => {      
          if (response.data === "Success") {                  
            setDisplaySuccessMessage(true);
          }
        })
        .catch((error) => {                
            console.log(error);
        });
  };

  if (dataLoading || !isLoggedIn) {
    return (
      <LoadingPanel></LoadingPanel>
    );
  }
  else {   
    return (     
      <div>
        <HashRouter>
            <DrawerRouterContainer 
              drawerItemsData={drawerItemsData} 
              setDisplayedSection={setDisplayedSection}
              handleDisplaySection={handleDisplaySection}
            >
              <Switch>
                <Route 
                  exact={true} 
                  path="/"  
                  render={(props) => (
                    <Dashboard 
                      {...props}
                      sectionsData={sectionsData}
                      filteredSectionsData={filteredSectionsData}
                      filteredDashboardSearchItemsData={filteredDashboardSearchItemsData}
                      notificationData={notificationData}
                      sectionsDDLData={sectionsDDLData}
                      typesDDLData={typesDDLData}
                      dashboardSearchItemsData={dashboardSearchItemsData}
                      displayNotification={displayNotification}
                      handleDisplayNotification={handleDisplayNotification}
                      handleFilteredSectionsData={handleFilteredSectionsData}
                      handleFilteredDashboardSearchItemsData={handleFilteredDashboardSearchItemsData}
                      handleItemsData={handleItemsData}
                      handleSelectedCardIndex={handleSelectedCardIndex}
                      handleDisplaySection={handleDisplaySection}
                      handleDisplayDashboardSearchItems={handleDisplayDashboardSearchItems}
                      itemsData={itemsData}
                      selectedCardIndex={selectedCardIndex}
                      displaySection={displaySection}
                      displayDashboardSearchItems={displayDashboardSearchItems}                                        
                    />
                  )}
                />
              
                {sectionsData.map((section, index) => {                         
                      return(
                        <Route
                          exact={true}
                          key={index}                        
                          path={"/" + section.name}                      
                          render={(props) => (
                            <Dashboard 
                              {...props}
                              sectionsData={sectionsData}
                              filteredSectionsData={filteredSectionsData}
                              filteredDashboardSearchItemsData={filteredDashboardSearchItemsData}
                              notificationData={notificationData}
                              sectionsDDLData={sectionsDDLData}
                              typesDDLData={typesDDLData}
                              dashboardSearchItemsData={dashboardSearchItemsData}
                              displayNotification={displayNotification}
                              handleDisplayNotification={handleDisplayNotification}
                              handleFilteredSectionsData={handleFilteredSectionsData}
                              handleFilteredDashboardSearchItemsData={handleFilteredDashboardSearchItemsData}
                              handleItemsData={handleItemsData}
                              handleSelectedCardIndex={handleSelectedCardIndex}
                              handleDisplaySection={handleDisplaySection}
                              handleDisplayDashboardSearchItems={handleDisplayDashboardSearchItems}
                              itemsData={itemsData}
                              selectedCardIndex={selectedCardIndex}
                              displaySection={displaySection}
                              displayDashboardSearchItems={displayDashboardSearchItems}                                                                                                              
                            /> 
                          )}
                        />                                   
                    );
                  })                                           
                }
                
                <Route 
                  exact={true} 
                  path="/About"  
                  render={(props) => (
                    <About
                      {...props}
                      overviewData={overviewData}                                        
                    />
                  )}
                />

                <Route 
                  exact={true} 
                  path="/Contact"  
                  render={(props) => (
                    <Contact 
                      {...props}
                      handleSubmitContact={handleSubmitContact}
                      displaySuccessMessage={displaySuccessMessage}
                      handleDisplaySuccessMessage={handleDisplaySuccessMessage}                                                           
                    />
                  )}
                />

                <Route 
                  exact={true} 
                  path="/Legal"  
                  render={(props) => (
                    <Legal
                      {...props}
                      disclaimerData={disclaimerData}                                   
                    />
                  )}
                />                               
              </Switch>
          </DrawerRouterContainer>
        </HashRouter>
       
        <AppBar position={"bottom"} positionMode={"sticky"} className="!h-24 md:!h-16">            
          <AppBarSection className="mr-auto ml-auto md:ml-5 md:text-base">
            <HashRouter>                                                    
              <FooterItems></FooterItems>         
            </HashRouter>                                     
          </AppBarSection>            
        </AppBar>      
      </div>
    );
  }
};

export default (App);
