import { noviConfig } from './config';
// import jwt from 'jsonwebtoken';

const redirectToSsoPage = () => {
  const ssoUri = noviConfig.jwtEndpoint + '?client_id=' + 
  noviConfig.clientId + '&redirect_uri=' + noviConfig.redirectUri;
  window.location.href = ssoUri;
};

// export const verifyJwtToken = (jwtToken) => {
//   jwt.verify(jwtToken, noviConfig.signingKey, (err, decoded) => {
//     if (err) {
//       console.error('JWT verification failed:', err.message);
//       // Handle invalid token here

//       return false;
//     } else {
//       // Token is valid, you can access the decoded payload
//       console.log('Decoded JWT payload:', decoded);

//       return true;
//     }
//   });
// };

export const handleJwtToken = (assignJwtCookie) => {
  const urlParams = new URLSearchParams(window.location.search);
  const jwtToken = urlParams.get('token');

  if (jwtToken) {   
    console.log('JWT Token:', jwtToken);

    // const tokenValidFlag = verifyJwtToken(jwtToken); 

    // if (tokenValidFlag) {
    //   const expirationTime = new Date(Date.now() + 60 * 60 * 1000); // 1 hour from now.

    //   // Set the JWT as a cookie with the specified expiration time
    //   assignJwtCookie('NoviSsoMembersPortalAuth', jwtToken, { expires: expirationTime, path: '/' });
    // }

    const expirationTime = new Date(Date.now() + 60 * 60 * 1000); // 1 hour from now.

    // Set the JWT as a cookie with the specified expiration time.
    assignJwtCookie('NoviSsoMembersPortalAuth', jwtToken, { expires: expirationTime, path: '/' });
  } else {
    redirectToSsoPage();
  }
};