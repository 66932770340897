// React imports.
import React from "react";
import { withRouter } from "react-router-dom";

// Kendo UI component imports. 
import { Drawer, DrawerContent, DrawerItem } from "@progress/kendo-react-layout";

// Custom component imports.
import { Header } from "./Header.js";

const CustomDrawerItem = (props) => {
    const { iconSvg, text, ...others } = props;

    return (
        <DrawerItem {...others}>          
            <span className="k-item-text truncate">{text}</span>
        </DrawerItem>
    );
};

class DrawerRouterContainer extends React.Component {   
    state = {
        expanded: true,
        selectedId: this.props.drawerItemsData.findIndex(x => x.selected === true),
        isSmallerScreen: window.innerWidth < 768,
        isMediumScreen: window.innerWidth <= 1024
    }

    componentDidMount() {
        window.addEventListener("resize", this.resizeWindow);      
        this.resizeWindow();

        this.browserLocationChange = this.props.history.listen(location =>  {
            console.log("Route changed");            
                      
            const selectedItem = this.props.drawerItemsData.find(item => item.route === location.pathname);
                     
            console.log("Selected item: ", selectedItem);
            // Hiding the section before react attempts to rendered it 
            // if we are navigating to the dashboard.
            if (typeof selectedItem !== "undefined") {
                if (selectedItem.sectionId === 0) {
                    this.props.handleDisplaySection(false);             
                }
            
                this.props.setDisplayedSection(selectedItem);
            }
       });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeWindow);
        this.browserLocationChange();      
    }

    resizeWindow = () => {
        this.setState({ 
            isSmallerScreen: window.innerWidth < 768, 
            isMediumScreen: window.innerWidth <= 1024
        });      
    }

    handleClick = () => {
        if (this.state.isMediumScreen) {
            this.setState((e) => ({expanded: !e.expanded}));
        }
    }

    handleSelect = (e) => {
        this.setState({selectedId: e.itemIndex, expanded: this.state.isMediumScreen ? false : true });
        this.props.history.push(e.itemTarget.props.route);      
        this.props.setDisplayedSection(e);   
        
        console.log("Selected value: ", e);
        console.log(this.props.history);
    }

    getSelectedItem = (pathName) => {     
        let currentPath = this.props.drawerItemsData.find(item => item.route === pathName);
       
        if (typeof currentPath !== "undefined" && currentPath.name) {
            return currentPath.name;
        }
    }
   
    render() {
        console.log("Drawer container rendered");
              
        let selected = this.getSelectedItem(this.props.location.pathname);
       
        console.log("Selected after render start: ", selected);

        return (
             <React.Fragment>
                <Header
                    onButtonClick={this.handleClick}
                    displayMenuIcon={this.state.isMediumScreen}                                
                />
                <Drawer                  
                    expanded={this.state.expanded}
                    items={this.props.drawerItemsData.map((item) => ({
                                ...item,
                                text: item.name,
                                selected: item.name === selected
                            }))
                    }
                    item={CustomDrawerItem}
                    position="start"
                    mode={this.state.isSmallerScreen ? "overlay" : "push"}
                    mini={this.state.isSmallerScreen ? false : true}
                    onOverlayClick={this.handleClick}
                    onSelect={this.handleSelect}                  
                >
                    <DrawerContent>
                        {this.props.children}
                    </DrawerContent>
                </Drawer>
        </React.Fragment>
        );
    }
};

export default withRouter(DrawerRouterContainer);