// React imports.
import React from "react";

// Router imports.
import { useHistory } from "react-router-dom";

const FooterItems = () => {
    const history = useHistory();

    return (   
        <div class="max-md:flex max-md:flex-col">
            <button                         
                className="normal-case md:px-5"
                onClick={() => history.push("/About")}                                                     
            >               
                About
            </button>
            <button                         
                className="normal-case md:px-5"
                onClick={() => history.push("/Contact")}                                     
            >  
                Didn’t find what you were looking for?
            </button>
            <button                         
                className="normal-case md:px-5"
                onClick={() => history.push("/Legal")}                                     
            >  
                Legal & Disclaimer
            </button>
        </div>
    );
};

export default FooterItems;