// React imports.
import {React} from "react";

const About = (props) => {
    return (      
        <div>              
            <div className="bg-white md:pt-8 md:mx-16 md:mt-8 md:px-8 md:rounded-t-md">
                <div className="grid">
                    {props.overviewData !== "" ?
                        <div>
                            <h2 className="font-light text-center md:mt-0 mt-8 mx-auto mb-8 text-2xl w-4/5">
                                About the IAA’s IN Compliance Guide
                            </h2>
                                                      
                            <div className="text-left mt-0 mx-auto mb-8 w-4/5" dangerouslySetInnerHTML={{ __html: props.overviewData.text}} >
                            </div> 
                        </div> 
                        : <div className="text-center mt-8 mb-8">
                            <p clasName="md:text-xl">
                                <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
                                <br /><br />
                                Content has not yet been created for this page.
                            </p>
                        </div>
                    }                          
                </div>
            </div>           
        </div>
    );
  };
  
export default About;